<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">团队架构</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <img width="100%" style="margin-top: 10px" src="../../assets/htmlimg/公司构架.png" alt="" />
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>